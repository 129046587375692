import { GET_CONNECTIONS_DETAIL } from "../types"

const initialState = {}

export default function connectionDetail(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CONNECTIONS_DETAIL:{
            return {
                ...state,
                ...action.data,
            }
        }
        default:
            return state;
    }
}