import React, { Component } from 'react';
import { Helmet } from "react-helmet";
//Redux
import { connect } from "react-redux";
import { Link, Route } from 'react-router-dom';
import ControlsBoxLogo from '../../components/ControlsBox/ControlsBoxLogo';
import { fetchContactUsPage } from "../../webService/actions/contactUsPage";
import './ConnectBuilder.css';
// import AnimationLogo from '../../components/Animations/AnimationLogo';
import ContactUsBuilder from './ContactUsBuilder';
import TextPageBuilder from './TextPageBuilder';





class ConnectBuilder extends Component {
    constructor(props) {
        super(props);
        //Here ya go
        this.props.history.listen((location, action) => {
            document.body.classList.remove('dark');
        });
    }

    componentDidMount() {
        this.onInit(this.props);
        document.body.classList.add('dark');
    }

    componentWillUpdate() {
        document.body.classList.add('dark');
    }

    componentWillUnmount() {
        document.body.classList.remove('dark');
    }

    onInit = props => {
        props.fetchContactUsPage();
    }

    showCookieSettings(e) {
        e.preventDefault();
        // console.log('showCookieSettings', e);
        window.Optanon.ToggleInfoDisplay();
    }

    render() {
        const { contactUsPage, match } = this.props;
        const ContactUsPage = (props) => {
            return (
                <ContactUsBuilder {...this.props.contactUsPage} />
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Contact</title>
                </Helmet>
                {contactUsPage ?
                    <section id="contactPage" style={{ backgroundImage: `url(${contactUsPage.BackgroundImage && contactUsPage.BackgroundImage.Url})` }}>
                        <div className="logoWrapper" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                            <ControlsBoxLogo isDark={true}></ControlsBoxLogo>
                        </div>

                        <Route exact path={`${match.path}`} component={ContactUsPage} />

                        <Route exact path={`${match.path}/privacy-policy/:id`} component={TextPageBuilder} />

                        <Route exact path={`${match.path}/terms-of-use/:id`} component={TextPageBuilder} />

                        <div className="footerContactPageWrapper">
                            <a href="https://landsec.com/policies/privacy-policy" target="_blank">{contactUsPage.PrivacyPolicy && contactUsPage.PrivacyPolicy.Text}</a> |
                            <a onClick={this.showCookieSettings} href="#" id="onetrust-cookie-settings" class="local">Cookie Settings</a> |
                            <Link to={contactUsPage.TermsOfUse ? contactUsPage.TermsOfUse.Url : '#'}>{contactUsPage.TermsOfUse && contactUsPage.TermsOfUse.Text}</Link>
                        </div>
                    </section>

                    :

                    <div>Loading</div>
                }

            </React.Fragment>
        );
    }
}

ConnectBuilder.propTypes = {};
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, { fetchContactUsPage })(ConnectBuilder);