import React, { Component } from 'react';
import { Switch, Route  } from 'react-router-dom'
import HomepageBuilder from './pages/HomepageBuilder/HomepageBuilder'
import CentresLocationsBuilder from './pages/CentresLocationsBuilder/CentresLocationsBuilder';
import CentreSpecificContentBuilder from './pages/CentreSpecificContentBuilder/CentreSpecificContentBuilder';
import ConnectBuilder from './pages/ConnectBuilder/ConnectBuilder';
import MapFiltered from './pages/MapFiltered/MapFiltered';
import LeasingPage from './pages/LeasingAndConnectionPage/LeasingPage';
import ConnectionsPage from './pages/LeasingAndConnectionPage/ConnectionsPage';
import ConnectionDetail from './pages/CentreSpecificContentBuilder/ConnectionDetail/ConnectionDetail';
import Navigator from './components/Navigator/Navigator';
import ReactGA from 'react-ga';
import MapFilteredConnection from './pages/MapFiltered/MapFilteredConnection';
import MapFilteredLeasing from './pages/MapFiltered/MapFilteredLeasing';
import Fullscreen from "react-full-screen";
import Fullpage from './components/Fullpage/Fullpage';

ReactGA.initialize('UA-125514397-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  state = {
    isFull: false,
  };

  goFull = () => {
    this.setState({ isFull: true });
  }

  render() {
    return (
        <React.Fragment>
         <Fullpage />

          <Fullscreen
          enabled={this.state.isFull}
          onChange={isFull => this.setState({isFull})}
          >
            <div id="nav-right"> <Navigator pathname={window.location.pathname} /></div>
            <main>
              <Switch>
                {/* <Route exact path="/locations/:id" component={CentresLocationsBuilder} /> */}
                <Route exact path="/locations" component={CentresLocationsBuilder} />
              </Switch>
            
              <Route path="/locations/:id" component={CentreSpecificContentBuilder} />

              <Switch>
                <Route exact path="/map/connections/:id" component={MapFilteredConnection} />
                <Route exact path="/map/connections" component={MapFilteredConnection} />
              </Switch>

              <Switch>
                <Route exact path="/connections" component={ConnectionsPage} />
                <Route exact path="/connections/:id" component={ConnectionDetail} />
              </Switch>

              <Switch>
                <Route exact path="/map/leasing/:id" component={MapFilteredLeasing} />
                <Route exact path="/map/leasing" component={MapFilteredLeasing} />
              </Switch>
              
              <Switch>
                <Route exact path="/leasing" component={LeasingPage} />
              </Switch>

              <Switch>
                <Route path="/contact" component={ConnectBuilder} />
              </Switch>

              <Route exact path="/" component={HomepageBuilder} />

              {/* <Route exact path="*" component={NotFound} /> */}
            </main>
          </Fullscreen>
        </React.Fragment> 
    );
  }
}

export default App;
 