import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import Overview from './Overview/Overview';
import Leasing from './Leasing/Leasing';
import './CentreSpecificContentBuilder.css'
// import AnimationLogo from '../../components/Animations/AnimationLogo'
import { isMobile } from "react-device-detect";

//Redux
import { connect } from "react-redux";
import { fetchLocation } from "../../webService/actions/location"

class LocationLandingPageBuilder extends Component {
    componentDidMount = () => this.onInit(this.props);
    onInit = props => {
        props.fetchLocation(props.match.params.id);
    }

    componentDidUpdate() {
        if (isMobile)
            window.scrollTo(0, 0);
    }

    render(props) {
        const { match } = this.props

        const OverviewPage = (props) => {
            return (
                <Overview {...this.props} />
            );
        }

        const LeasingPage = (props) => {
            return (
                <Leasing {...this.props} />
            );
        }

        return (
            <React.Fragment>
                <div className="body centreSpecificContent">
                    <Switch>
                        <Route path={`${match.path}/overview`} component={OverviewPage} />
                        <Route exact path={`${match.path}`} component={OverviewPage} />
                    </Switch>
                    <Switch>
                        <Route path={`${match.path}/leasing`} component={LeasingPage} />
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, { fetchLocation })(LocationLandingPageBuilder);