import React from 'react';

const LevelSelector = ({isActive, LeadColour}) => {
    return (
        <div> 
            <img id="levSelectorShadow" alt="level selector" src={require('../../assets/Images/Leasing/levSelectorShadow.png')} />
            <svg id="LevelSelector" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.18 65.65">
                <polygon className="LS-1" style={isActive ? { fill : LeadColour} : {}} points="0 32.26 66 56.28 189.18 7.02 132.15 0 0 32.26"/>
                <polygon className="LS-2" style={isActive ? { fill : LeadColour} : {}} points="66 56.28 66.51 65.64 188.77 11.88 189.18 7.03 66 56.28"/>
                <polygon className="LS-3" style={isActive ? { fill : LeadColour} : {}} points="0.93 39.53 66.51 65.65 66 56.28 0 32.26 0.93 39.53"/>
            </svg>
        </div> 
    );
};

export default LevelSelector;