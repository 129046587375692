import { MAP_FILTERED_CONNECTIONS } from "../types"

const initialState = { 
}

export default function mapFilteredConnections(state = initialState, action = {}) {
    // console.log("REDUCERS: MAP_FILTERED_CONNECTIONS", state, action.data)
    switch (action.type) {
        case MAP_FILTERED_CONNECTIONS:{
            return {
                ...state,
                ...action.data
            }
        }
        default:
            return state;
    }
}