import React from 'react';
// import { Link } from 'react-router-dom';
// import { Player } from 'video-react';
import "video-react/dist/video-react.css"; 

import CentresBoxItem from '../../CentresLocations//CentresBox/CentresBoxItem';
import './CentresBox.css';

const locationsBox = ({ locations, match}) => {
    const transformedLocation = locations.map((location, i) => {
        return <CentresBoxItem key={i} ClassName="grid-item" 
                    TextLabel={location.Name} 
                    LinkUrl={`${match.url}/detail/${location.Id}`} 
                    ImageUrl={location.GridPlaceholderImage !== null && location.GridPlaceholderImage.Url}
                    GridVideoUrl={location.GridVideo !== null && location.GridVideo.Url} 
                />
    });

    return ( 
            <div className="gridix-list-items">
                {transformedLocation }
            </div>
    );
};

export default locationsBox;