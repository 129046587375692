import React from 'react';

const ContactUsBuilder = (props) => {
    return (
        <div className="getInTouchWrapper">
            <h1><strong>{props.Title}</strong></h1>
            <h2><strong>{props.Subtitle}</strong></h2>
            <div className="getInTouchLink"></div>
            <h3 className="getInTouchEmail"><a href={`mailto:${props.EmailAddress}`}>{props.EmailAddress}</a></h3>
        </div>
    );
};

export default ContactUsBuilder;