import { GET_CONNECTIONS_PAGE } from "../types"

const initialState = {
    data: [
    ]
}

export default function connectionsPage(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CONNECTIONS_PAGE:{
            return {
                ...state,
                ...action,
            }
        }
        default:
            return state;
    }
}