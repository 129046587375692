import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './ControlsBox.css';
import logo from '../../assets/logo.svg'
import { Grid } from 'semantic-ui-react'



class ControlsBox extends Component {
    state = {
        toggleFlag : false
    }
    render() {
        const { children } = this.props;
        return (
            <div className="grid-head-container">
                <div className="grid-head-body">
                    <div className="ghb-wrapper">
                        <div className="x-logo-wrapper">
                            <Link to="/">
                                <img id="logo-img" className="noDelay" src={logo} alt={"logo"} style={{position: 'relative'}} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="grid-head-footer">
                    <div className="row">
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    {children}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default ControlsBox;