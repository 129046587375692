import React from 'react';
import { Link } from 'react-router-dom';

import { Player } from 'video-react';
import "video-react/dist/video-react.css"; 

//import ControlsBox from '../../ControlsBox/ControlsBox';
import './CentresBox.css';

const locationsBoxItem = ({ClassName,TextLabel, LinkUrl, ImageUrl, GridVideoUrl }) => {
    return ( 
        <Link to={LinkUrl} className={ClassName}>
            <div className="grid-item-bg">
                <div className="st-bg grid-item-bg-img" style={ImageUrl !== false ? { backgroundImage: 'url("' + ImageUrl + '")' } : {}}>
                    {GridVideoUrl !== null && GridVideoUrl !== undefined && GridVideoUrl !== false   &&
                        <Player
                            poster={ImageUrl}
                            src={GridVideoUrl}
                            playsInline={true}
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            disableCompletely={true}
                        />
                    }
                </div>

                {/* <Vimeo video={location.GridVimeoId} autoplay="true" muted="true"/> */}
                {/* <div className="grid-item-bg-img" style={{ backgroundImage: 'url("' + location.MapThumbnailImage.Url +'")'}}></div> */}
            </div>
            <div className="grid-item-content ">
                <span className="grid-item-symbol arrow"> </span>
                <span className="grid-item-title small">{TextLabel}</span>
            </div>
        </Link >
    );
};

export default locationsBoxItem;