import React, { Component } from 'react';

class Fullpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen : false
        }

        this.remainingTime = 3000;
        this.startTime = 0;
        this.timerId = null;
    }

    componentDidMount(){
        this.setState({isOpen:true});
    }


    componentDidUpdate( prevProps, prevState ){
        // if toggled to display
        if( !prevState.isOpen && this.state.isOpen ){
            // this.timerId = setTimeout( this._toggleBtn, 3000 ); //delay 3 seconds
            this._resume(this._toggleBtn, 2000);
        }
    }

    _pauseTime = () => {
        window.clearTimeout(this.timerId );
        this.remainingTime -= Date.now() - this.startTime;
    }

    _resume = (callback, delay) => {
        this.startTime = Date.now();
        window.clearTimeout(this.timerId);
        this.timerId = window.setTimeout(callback, this.remainingTime + delay);
    }

    _toggleBtn = () => {
        this.setState({isOpen:!this.state.isOpen})
    }

    _OverBtn = () => {
        this._pauseTime();
    }

    _LeaveBtn = () => {
        this._resume(this._toggleBtn, 1000);
    }

    render() {
        const {isOpen} = this.state;

        return (
            <div onMouseOver={this._OverBtn} onMouseLeave={this._LeaveBtn} className={`fullScreen ${isOpen ? "open" : "close"}`} onClick={this.goFull}>BETTER VIEWED FULL SCREEN</div>
        );
    }
}

export default Fullpage;