import { MAP_FILTERED_LEASING } from "../types"

const initialState = {}

export default function mapFilteredLeasing(state = initialState, action = {}) {
    switch (action.type) {
        case MAP_FILTERED_LEASING:{
            return {
                ...action.data
            }
        }
        default:
            return state;
    }
}