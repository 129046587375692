import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { fetchHomepage } from "../../webService/actions/homepage";
import ControlsBoxLogo from '../../components/ControlsBox/ControlsBoxLogo';
import VideoCover from '../../components/Videos/VideoCover';
import { Helmet } from "react-helmet";
import './HomepageBuilder.css';
import AnimationLogo from '../../components/Animations/AnimationLogo';

class HomepageBuilder extends Component {

    state = {
        isVideoReady: false,
        isVimeo: false
    }

    componentDidMount() {
        this.onInit(this.props);
    }

    onInit = props => {
        props.fetchHomepage();
    }

    eventVideoBufferedHandler = () =>{
        this.setState({ isVideoReady: true});
    }

    render(){
        const { homepage } = this.props;

        return (
            <AnimationLogo isReady={this.state.isVimeo ? this.state.isVideoReady : true} hasCallBack={this.state.isVimeo}> 
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Homepage</title>
                    <meta name="Description" content="Landsec Connect | Put your description here."></meta>
                </Helmet>

                <div className="logoWrapper home">
                    <ControlsBoxLogo noLink={true}  noBg={true}></ControlsBoxLogo>
                </div>
                <div className="CarouselTextWrapper" style={{backgroundColor: "black"}}>
                    {
                        homepage &&
                        homepage.Video &&
                            <VideoCover Src={`${homepage.Video.Url}`} isVimeo={this.state.isVimeo} eventVideoBufferedHandler={this.eventVideoBufferedHandler} />
                    }

                    <div className="landingpage-maincontrols">
                        {
                            homepage &&
                            homepage.HomePageItems &&
                            homepage.HomePageItems.map((item, key) => {
                                return (
                                    <div className="landingpage-maincontrols-row" key={key}>
                                        <Link className="landingpage-maincontrols-row-link" to={item.Link}>
                                            <div className="landingpage-maincontrols-row-index">0{key+1}</div>
                                            <div  className="landingpage-maincontrols-row-title ">{item.DisplayText}</div>
                                            <div  className="landingpage-maincontrols-row-cta">{item.ButtonText}</div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </AnimationLogo>
        );
    }
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, { fetchHomepage })(HomepageBuilder);