import React, { Component } from 'react';
import FlexyGrid from './FlexyGrid';
import { connect } from "react-redux";
import { fetchConnectionPage } from "../../webService/actions/connections"

class ConnectionPage extends Component {
    componentDidMount() {
        this.onInit(this.props);
    }

    onInit = props => {
        props.fetchConnectionPage();
    }

    render() {
        const { match, connectionsPage, location, title} = this.props;
        return (
            <React.Fragment>
                <FlexyGrid title="Connections" data={connectionsPage} match={match}    />
            </ React.Fragment>
        );
    }
}

ConnectionPage.propTypes = {};
const mapStateToProps = (state) => {
    return { 
        connectionsPage: state.connectionsPage.data
    }
}
export default connect(mapStateToProps, { fetchConnectionPage })(ConnectionPage);