import { combineReducers } from 'redux';
import navigator from "./reducers/navigator";
import locations from "./reducers/locations";
import _location from "./reducers/location";
import eventSpace from "./reducers/eventSpace";
import homepage from "./reducers/homepage";
import leasingPage from "./reducers/leasingPage";
import connectionsPage from "./reducers/connectionsPage";
import opportunityPage from "./reducers/opportunityPage";
import contactUsPage from "./reducers/contactUsPage";
import textPage from "./reducers/textPage";
import mapFilteredConnections from "./reducers/mapFilteredConnections";
import mapFilteredLeasing from "./reducers/mapFilteredLeasing";
import connectionsDetail from "./reducers/connectionsDetail";

export default combineReducers({
    homepage,
    navigator,
    locations,
    mapFilteredLeasing,
    mapFilteredConnections,
    _location,
    eventSpace,
    leasingPage,
    connectionsPage,
    opportunityPage,
    contactUsPage,
    textPage,
    connectionsDetail
});