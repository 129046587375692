import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 

import './ControlsBox.css';
// import { Dropdown } from 'semantic-ui-react'
import Menu from '../Location/Menu'


class ControlsBoxBL extends Component {
    render() {
        const { backgroundColor, children, className } = this.props;
        return (
            <div className={`grid-head-container ${className}`}>
                <div className="grid-head-body-pink ghb" style={{backgroundColor: backgroundColor}}>
                    <div className="ghb-wrapper-pink-text">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default ControlsBoxBL;

