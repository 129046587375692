import React from 'react';
import { Link } from 'react-router-dom';

import './ControlsBox.css';
// import logo from '../../assets/logo.svg'
import logo_Mobile from '../../assets/logo_mobile.svg'
// import logo_Landsec from '../../assets/logo_landsec.svg'
import LandsecConnectLogo from '../../assets/LandsecConnectLogo.gif'
import { Grid } from 'semantic-ui-react'

const controlsBox = ({children}) => {

    return (
        <div className="grid-head-container">
            <div className="grid-head-body-black">
                <div className="ghb-wrapper-black">
                    <div className="x-logo-wrapper">
                        <Link to="/">
                            {/* <img id="logo-img" src={logo} alt={"logo"} />
                            <img id="logo-img-landsec" src={logo_Landsec} alt={"logo"} /> */}
                            <img src={LandsecConnectLogo} id="LandsecConnectLogo" style={{width : 150}} alt={"logo"}/>
                            <img id="logo-img-mobile" src={logo_Mobile} alt={"logo"} />
                        </Link>
                    </div>
                </div>
                <div className="ghb-wrapper-black-text cbb">
                   {children}
                </div>
            </div>
        </div>
    );
};

export default controlsBox;