import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchMapFilteredConnections } from "../../webService/actions/mapFilteredConnections"
import { Helmet } from "react-helmet";
import MapFiltered from './MapFiltered';


class MapFilteredConnection extends Component{
    state = {
        filterTag: "",
        checkboxChanged: ""
    }

    componentDidMount = () => this.onInit(this.props);
    
    onInit = props => { 
        props.fetchMapFilteredConnections();  
        this.setFilterHandler();
    }

    setFilterHandler = props => {
        this.setState({
            filterTag: this.props.match.params.id
        });
    }

    filterChangeHandler = (filterTag) => {
        this.setState( {
            filterTag: this.state.filterTag !== filterTag ? filterTag : ""
        })
    }

    render () {
        const { locations, match } = this.props;
        return (
            <React.Fragment >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Map View - Connections</title>
                </Helmet>
                {locations &&
                    <MapFiltered id={match.params.id} arrayFilter={locations.Filter} title={"Connections"} locations={locations} isLeasing={false} match={match} />
                }
            </React.Fragment>
        );
    }
}

MapFilteredConnection.propTypes = {
    fetchMapFilteredConnections: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        locations: state.mapFilteredConnections
    }
}

export default connect(mapStateToProps, { fetchMapFilteredConnections })(MapFilteredConnection);