import React, { Component } from 'react';
import ControlsBox from '../../components/ControlsBox/ControlsBox';
import './FlexyGrid.css';
import Masonry from 'react-masonry-component';
import { Helmet } from "react-helmet";
import FlipBox from '../../components/Box/FlipBox'

const masonryOptions = {
    transitionDuration: 0,
    columnWidth: '.grid-sizer-b',
    itemSelector: '.grid-item-b',
    percentPosition: true,
    gutter: 0,
    isOriginLeft: true,
    horizontalOrder: true
};
class FlexyGrid extends Component {
    render() {
        const { match, data, location, title} = this.props;

        var templatePattern = {
            template1: [{x: 4, y:4}],
            template2: [{ x: 3, y: 2 }, { x: 4, y: 2 }],
            template3: [{ x: 2, y: 2 }, { x: 1, y: 4 }, { x: 3, y: 2 }],
            template4: [{ x: 2, y: 2 }, { x: 1, y: 2 }, { x: 2, y: 2 }, { x: 2, y: 2 }],
            template5: [{ x: 2, y: 2 }, { x: 1, y: 2}, { x: 1, y: 2}, { x: 2, y: 2 }, { x: 1, y: 2 }],
            template6: [{ x: 2, y: 2 }, { x: 1, y: 2 }, { x: 1, y: 2 }, { x: 1, y: 2 }, { x: 1, y: 2 }, { x: 1, y: 2 }],
            template7: [{ x: 1, y: 2 }, { x: 1, y: 3 }, { x: 1, y: 2 }, { x: 1, y: 2 }, { x: 1, y: 2 }, { x: 1, y: 1 }, { x: 1, y: 2 }]
        };

        var oppppp = data.slice(0, 7);
        const numberOpportunities = oppppp.length;

        const childElements = oppppp.map(function (element, i) {
            return (
                <div key={i} className={`Opportunity-landig-page grid-item-b el-${i} grid-item-${templatePattern[`template${numberOpportunities}`][i].x}-${templatePattern[`template${numberOpportunities}`][i].y}`}>
                    <FlipBox name={element.Name} image={element.Image.Url} description={element.FullDescription} leadColour={element.Colour} to={element.Link} match={match} />
                </div>
            );
        });
      
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Opportunities</title>
                </Helmet>
                <ControlsBox > {title} </ControlsBox>
                <section id="opportunitiesLandingPageBuilder" className={"oLPBWrapper-" + numberOpportunities}>
                    <Masonry
                        options={masonryOptions} // default {}
                        className={'my-gallery-class'}
                    >
                        <div className="grid-sizer-b"></div>
                        {numberOpportunities > 1 &&
                            <div className="grid-item-b grid-item-jolly" style={{ backgroundColor: "black" }} ></div>
                        }
                        {childElements}
                    </Masonry>
                </section>
            </React.Fragment>
        );
    }
}

export default FlexyGrid;
