import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import MapBoxRow from './MapBoxRow';
import TooltipLocation from './TooltipLocation'
import { isMobile } from "react-device-detect";


import MapGL, { Marker, Popup, NavigationControl, FlyToInterpolator } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';


import 'mapbox-gl/dist/mapbox-gl.css';
import './MapBox.css';

const MAPBOX_TOKEN = 'pk.eyJ1IjoibGFuZHNlYy1jb25uZWN0IiwiYSI6ImNqdm1rZG1kZDBvYW40YXZucG0zb240Y3kifQ.uddevR6QShqTKIhfPGQukQ';
const navStyle = {
    position: 'absolute',
    bottom: 0,
    right: '5px',
    padding: '10px'
};
const maxBounds = {
    SouthWest: { longitude: -25.4844, latitude: 48.6499 },
    NorthEast: { longitude: 10.5827, latitude: 60.3621}
}

class MapBox extends Component {
    state = {
        mapStyle: 'mapbox://styles/landsec-connect/cjvmkfcj60rab1cmvx7ecyvxx',
        viewport: {
            longitude: -3.118092,
            latitude:  54.8, //54.3 
            zoom: 5.45,
            bearing: 0,
            pitch: 0,
            width: 500,
            height: 500,
            // maxZoom: 6
        },
        popupInfo: null,
        popupsInfo: [],
        MarkerToggleClasses: []
    };

    componentDidMount() {
        window.addEventListener('resize', this._resize);
        const { locations } = this.props;

        this._resize();
    }

    componentDidUpdate(prevProps, prevState) {
         //If the Filter change then close alll popupsInfo
         if(prevProps.filterTag.length !== this.props.filterTag.length){
            this.setState({ popupsInfo: [] });
           // this._goToViewport({ MapLongitude: 1.5, MapLatitude: 54.5, transitionDuration: 0 });
         }
    }

    instersactionHelper = (array1, array2) => {
        if(array2 && array1) {
            return array1.filter(function(e) {
                return array2.indexOf(e.Id) > -1;
            });
        }else{
            return false;
        }
        
    }
  
    componentWillUnmount() {
        window.removeEventListener('resize', this._resize);
    }

    _resize = () => {
        this.setState({
            viewport: {
                ...this.state.viewport,
                width: this.props.width || window.innerWidth,
                height: this.props.height || window.innerHeight
            }
        });
    };

    _helperConcatAndDeDuplicateObjects = (p, ...arrs) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);

    _helperSearchInObj = (nameKey, myArray) =>{
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].Id === nameKey) {
                return myArray[i];
            }
        }
    }

    _renderCityMarker = (city, index) => {
      

        // if(!this.props.isLeasing){
        //     filteredLocations = city.OpportunityTypes ? city.FilterableItems.filter(item => item.Id === this.props.FilterableItems) : 0;
        // }else{
        //     filteredLocations = city.LocationTypes ? city.LocationTypes.filter(item => item.Id === this.props.FilterableItems) : 0;
        // }

        // let filteredLocations = city.FilterableItems ?  city.FilterableItems.filter(item => item.Id === this.props.filterTag) : 0;
        let filteredLocations = city.FilterableItems ?  this.instersactionHelper(city.FilterableItems,  this.props.filterTag) : 0;
        let lastSelectedFilter = this.props.filterTag[this.props.filterTag.length - 1];
        let filterLocationsColours = this.props.filterTag[0];

        if (city.FilterableItems.filter(e => e.Id === lastSelectedFilter).length > 0) {
            filterLocationsColours =  this._helperSearchInObj(lastSelectedFilter, city.FilterableItems);
        }else{
            let intersDeepFilters = this.instersactionHelper(city.FilterableItems, this.props.filterTag);
            filterLocationsColours = intersDeepFilters[0];
        }

        return  (
          
            <Marker key={`M-marker-${index}`}
                longitude={city.MapLongitude}
                latitude={city.MapLatitude}
            >
                {filteredLocations &&
                    ( filteredLocations.length > 0 || this._clearArray(this.props.filterTag).length == 0) &&

                    <div className={`${this.state.popupsInfo.findIndex((el) => el.Id == city.Id) !== -1 ? "marker active" : "marker"} ` }
                        ref={el => this["marker-"+index] = el}
                        style={{backgroundColor: filterLocationsColours ?  filterLocationsColours.Colour : (this.props.injectedColour ? this.props.injectedColour : "white")}}
                        onClick={(el) => {
                                window.filterTag = this.props.filterTag;
                                if(!this.props.isFull){
                                    var r = this._helperConcatAndDeDuplicateObjects('Id', this.state.popupsInfo, city);
                                    this.setState({ popupsInfo: r });
    
                                    if(!this.props.isFull){
                                        this._goToViewport({ ...city, ...{ transitionDuration: 500, offset: 0, zoom: this.state.viewport.zoom} });
                                    }
                                }
                              
                            }
                        }
                    /> 
                // :
                //     <div className="marker inactive"                        
                //         ref={el => this["marker-"+index] = el}
                //         onClick={(el) => {
                //             var r = this._helperConcatAndDeDuplicateObjects('Id', this.state.popupsInfo, city);
                //             this.setState({ popupsInfo: r });
                //             // this._goToViewport({ ...city, ...{ transitionDuration: 500, offset: -3 } });
                //             }
                //         }
                //      /> 
                }
                
            </Marker>
        )
    }

    _clearArray = (array) => {
       return ( 
            array.filter(function (el) { return el != null; })        
        ) 
    }

    // _renderPopupPlus = (city, index) => {
    //     if (city.DisplayDetailOnMapLoad){
    //          this.setState({ popupsInfo: [...this.state.popupsInfo, city] });
    //     }
    // }


    _renderPopup = (city, index) =>  {
        // city.DisplayDetailOnMapLoad;
        return true && (
            <Popup tipSize={5} key={`Popup-${index}`}
                anchor= {city.LabelPosition == 73 ? "right" : "left" }
                longitude={city.MapLongitude}
                latitude={city.MapLatitude}
                closeOnClick={true}
                dynamicPosition = {false}
                onClose={() => {
                        //var r = this._helperConcatAndDeDuplicateObjects('Id', this.state.popupsInfo, city);
                      
                        var b = this.state.popupsInfo.findIndex((el) => el.Id == city.Id);
                        var _popupsInfo = this.state.popupsInfo;
                        _popupsInfo.splice(b, 1);

                        this.setState({ popupsInfo: _popupsInfo }); 
                    }
                } 
            >

                <TooltipLocation {...city} isLeasing={this.props.isLeasing} filterTag={this.props.filterTag[0]} match={this.props.match} isFull={this.props.isFull}/>
        
                {/* <div className="testDivWrapper"> <img width={240} src={popupInfo.MapThumbnailImage.Url} /></div> */}
            </Popup>
        );
    }

    _updateViewport = (viewport) => {
        if (viewport.longitude < maxBounds.SouthWest.longitude) { //minLongitude
            viewport.longitude = maxBounds.SouthWest.longitude;
        }
        else if (viewport.longitude > maxBounds.NorthEast.longitude) { //maxLongitude
            viewport.longitude = maxBounds.NorthEast.longitude;
        }
        else if (viewport.latitude < maxBounds.SouthWest.latitude) { //minLatitude
            viewport.latitude = maxBounds.SouthWest.latitude;
        }
        else if (viewport.latitude > maxBounds.NorthEast.latitude) { //maxLatitude
            viewport.latitude = maxBounds.NorthEast.latitude;
        }

        this.setState({ viewport: { ...this.state.viewport, ...viewport } });
    }

    _onViewportChange = (viewport) => {
        this.setState({ viewport: { ...this.state.viewport, ...viewport }});
    };

    _goToViewport = ({ MapLongitude = 0, MapLatitude = 0, zoom = 5.5, transitionDuration = 2000, offset = -1}) => {
        this._onViewportChange({
            longitude: MapLongitude - offset,
            latitude: MapLatitude,
            zoom: zoom,
            transitionInterpolator: new FlyToInterpolator(),
            transitionDuration: transitionDuration
        });
    };

    render() {
        const { viewport, mapStyle, popupsInfo } = this.state;
        const { locations, isReady = false, setFilterHandler, filterTag, isFull } = this.props;

        let _viewport = isFull ? {...viewport, ...{zoom : 4.5}} : viewport;


        return (
            <React.Fragment>
                    <div className={`mapBoxWrapper ${isFull ? "Full" : ""}`} >
                        <MapGL
                            {..._viewport}
                            minZoom={5}
                            reuseMaps = {true}
                            scrollZoom={!isFull}
                            mapStyle={mapStyle}
                            onViewportChange={this._updateViewport}
                            onLoad={() => {
                                    this._goToViewport({ MapLongitude: -3.118092, MapLatitude: 54.8, transitionDuration: 900, zoom: isFull ? 4.5 : 5.5, offset: 0});
                                    setTimeout(() => {
                                        // this.props.locations.map(this._renderPopupPlus);
                                        // setFilterHandler();
                                    }, 2000);
                                }
                            }
                            onClick={() => { this.setState({ popupsInfo: [] }); } }
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                        >

                        {!isFull &&
                            <div className="nav" style={navStyle}>
                                <NavigationControl onViewportChange={this._updateViewport} />
                            </div>
                        }
                            { locations &&
                                <div>
                                    {
                                        locations.map(this._renderCityMarker)
                                    }
                                    {
                                        popupsInfo.map(this._renderPopup)
                                    }
                                </div>
                            }
                        </MapGL>
                    </div>

                    <div className="mapBoxRowWrapper">
                        <MapBoxRow locations={locations} filterTag={filterTag} instersactionHelper={this.instersactionHelper}/>
                    </div>
            </React.Fragment>
        );
      }
}


const mapStateToProps = (state) => {
    return {
        // locations: state.locations.LocationList,
        isReady: true,
        //     this.setState({ popupsInfo: [...this.state.popupsInfo, city] });
        // )
    }
}
export default connect(mapStateToProps, null)(MapBox);