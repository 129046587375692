import React, { Component } from 'react';
import { TweenMax, TweenLite, TimelineMax} from 'gsap'

import './Animations.css';

var $TimelineMax= null;

const OFFSET = 0.5;
const ACTIVE_ANIMATION  = true;

class AnimationLogo extends Component {
    state = {
        screen: { width: window.innerWidth, height: window.innerHeight },
        logo: { width: 0, height: 0 },
        canvas: {zIndex: 0},
        isReady: false
    }

    componentDidMount() {
       
        this.Init();         

        //#Event updateWindowDimensions Add
        window.addEventListener('resize', this.onWindowsResizeHandler);
    }

    Init = () =>{
      
          

            if (ACTIVE_ANIMATION) {
                TweenLite.set([this.sta1, this.sta2, this.sta3, this.sta4, this.stb1, this.stb2, this.stb3, this.stb4], { style: { transform: "" }, force3D: true });
                this.sta2.setAttribute("className", "transformDestroyer");

                //Init GASP Library for animation
                this.TimelineMaxHandler();

                this.updateWindowDimensions();

                this.centerLogoToScreenHandler();

                this.createElasticAnimationInHandler();
                
            }else{
                this.destroyCanvasAnimation();
            }

      //  document.body.classList.add('dark');
    }

    //On Animation finish Destroy everything
    destroyCanvasAnimation = () => {
        if (this.props.isReady == true) {
            TweenMax.to(this.canvas, 0.45 , { opacity: 0, force3D: true, onComplete: () => { 
                if (this.canvas)
                    this.canvas.classList.add('destroy') 
                } 
            });
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowsResizeHandler); //#Event updateWindowDimensions Remove
    }

    //#HANDLE EVENT WINDOWS RESIZE
    onWindowsResizeHandler = () => {
        this.updateWindowDimensions();
        this.centerLogoToScreenHandler();
    }

    componentWillUpdate(nextProps, nextState) {
        this.centerLogoToScreenHandler();
        this.resizeMaskToScreenHandler();
    }

    //#UPDATE WINDOWS DIMENTION TO THE STATE 
    updateWindowDimensions = () => {
        this.setState({ screen: {width: window.innerWidth, height: window.innerHeight}});
    }

    //#RESIZE BLACK MASK TO FIT ENTIRE SCREEN
    resizeMaskToScreenHandler = () => {
        TweenLite.set(this.mask, { attr: { width: this.state.screen.width, height: this.state.screen.height }, force3D: true });
    }

    //#POSITION LOGO TO CENTER OF THE SCREEN
    centerLogoToScreenHandler  = () => {
        var _scale = 1
        if (this.state.screen.width >= 1200 && this.state.screen.width <= 1700){
            _scale = 0.9
        }
        else if (this.state.screen.width >= 620 && this.state.screen.width < 1200) {
            _scale = 0.7
        }
        else if (this.state.screen.width >= 420 && this.state.screen.width < 620) {
            _scale = 0.5
        }
        else if (this.state.screen.width >= 325 && this.state.screen.width < 420) {
            _scale = 0.35
        }
        else if (this.state.screen.width < 325 ) {
            _scale = 0.25
        }

        TweenLite.set([this.logoWrapper1, this.logoWrapper2], { x: this.state.screen.width / 2 - 465, y: this.state.screen.height / 2 - 420, scale: _scale, transformOrigin: 'center center', force3D: true });
    }

    createElasticAnimationInHandler = () =>{
        // TweenLite.set([this.sta1, this.sta2, this.sta3, this.sta4, this.stb1, this.stb2, this.stb3, this.stb4], { opacity: 0, scale: 0, transformOrigin: 'center center', force3D: true  });
        // TweenLite.to([this.sta1, this.sta2, this.sta3, this.sta4, this.stb1, this.stb2, this.stb3, this.stb4], 2 , { opacity: 1, scale: 1, ease: 'Elastic.easeInOut', transformOrigin: 'center center', force3D: true, onComplete: this.destroyCanvasAnimation });
        
        //Handle Lines animation of Logo
        TweenLite.set([this.sta1, this.sta2, this.sta3, this.sta4], { opacity: 1, scale: 0, transformOrigin: 'center center', force3D: true  });
        TweenLite.to([this.sta1, this.sta2, this.sta3, this.sta4], 2, { opacity: 1, scale: 1, ease: 'Elastic.easeInOut', transformOrigin: 'center center', force3D: true, onComplete: this.ComponentHandler }); // onComplete: this.destroyCanvasAnimation

        //Handle Mask Container animation of Logo
        TweenLite.set([this.stb1, this.stb2, this.stb3, this.stb4], { opacity: 0, force3D: true });

        // TweenLite.set([this.stb1, this.stb2, this.stb3, this.stb4], { opacity: 0, scale: 0, transformOrigin: 'center center', force3D: true });
        // TweenLite.to([this.stb1, this.stb2, this.stb3, this.stb4], 2, { opacity: 0, scale: 1, ease: 'Elastic.easeInOut', transformOrigin: 'center center', force3D: true, onComplete: this.destroyCanvasAnimation });
    }

    TimelineMaxHandler = () => {
        if ($TimelineMax == null){
            $TimelineMax = new TimelineMax({ delay: 0.25, yoyo: true, transformOrigin: 'center center', ease: "Sine.easeInOut" });
        }
        return $TimelineMax
    }

    ComponentHandler = () => {
        if (this.props.isReady == true) {
            // this.createElasticAnimationInHandler();
            TweenLite.set([this.stb3], { opacity: 1, force3D: true, rotation: 0.01, delay: 0  });
            TweenLite.set([this.stb1], { opacity: 1, force3D: true, rotation: 0.01, delay: 0.4  });
            TweenLite.set([this.stb4], { opacity: 1, force3D: true, rotation: 0.01, delay: 0.8  });
            TweenLite.set([this.stb2], { opacity: 1, force3D: true, rotation: 0.01, delay: 1.4 , onComplete: this.destroyCanvasAnimation});
        }
    }

    render() {
        const { children, darkMode = false, isReady = true, hasCallBack = false, imgUrl=false, bgColor=false} = this.props;
   
        return (
            // <div ref={c => this.box = c} />
        
            <React.Fragment>
                {hasCallBack && this.ComponentHandler()}  

                    <div id="canvas" ref={c => this.canvas = c}> 
                        <svg className="canvasLogo hero-mask js-hero-mask" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ enableBackground: "new 0 0 100% 100%" }}> {/*viewBox="0 0 100% 100%"*/}
                            <defs>
                                <mask width="100%" height="100%" id="mask-0" ref={c => this.mask = c}>
                                    <rect id="rec1" width="100%" height="100%" fill="#fff" className="js-hero-mask-rect"></rect>
                                    <g className="wrappor" style={{ mask: "url(#mask-0)" }} ref={c => this.logoWrapper1 = c} >
                                        <g className="wrapporInner" ref={c => this.logoWrapper1Inner = c}>
                                            <path className="st0 path1 p" ref={c => this.stb1 = c} d="M527.2,848.2c-217.2,0-387.7-189.9-393.9-423.1c-4.1-159.8,126.1-286.2,261.1-286.2c12.8,0,25.6,1.1,38.2,3.3
                                            C337.9,189,261.1,312.9,261.1,425.1c0,157.7,119.4,286,266.2,286c112.9,0,216.2-79.9,252.1-194.6h132.3
                                            C872.2,709,711.2,848.2,527.2,848.2" style={{mask: "url(#mask-0)"}}  />
                                            <path className="st0 path4 p4" ref={c => this.stb2 = c} d="M401.6,2c277.5-4.8,478.3,139.3,518,331.8H789.8C744.6,123.9,571.8,4.1,407.2,2H401.6z" />
                                            <path className="st0 st0 path2 p2" ref={c => this.stb3 = c} d="M651.2,516.6c-23.8,74.9-88.7,159.7-156.1,192.5c12.6,1.8,25.4,2.5,38.1,2c100.5-1,209.6-75.3,246.1-194.5
                                            H651.2z" />
                                            <path className="st0 st0 path3 p" ref={c => this.stb4 = c} d="M401.3,848.2C181.6,848.2,2.8,658.4,2.8,425.1S181.5,2,401.3,2c186.2,0,349.2,139.3,389.1,331.7H656.3
                                            c-37.1-116.5-149.1-197-265-194.7C245.2,141.9,121,283.5,134.7,443c14.4,209.9,150.1,347.6,284,388.9
                                            c60.4,17.9,101.9,16.3,114.1,16.3L401.3,848.2z" />
                                        </g>
                                    </g>
                                </mask>
                            </defs>
                            <g style={{ mask: "url(#mask-0)"}}>
                                <rect id="maskBg" width="100%" height="100%" className="js-hero-mask-rect" fill={darkMode == true ? "#fff" : "#000"} ref={c => this.heroMaskRect = c}></rect>
                            </g>
                            <g className="wrappor" ref={c => this.logoWrapper2 = c}>
                                <g className="wrapporInner catchme" ref={c => this.logoWrapper2Inner = c}>
                                    <g>
                                        <path className="st1 path1" ref={c => this.sta1 = c} d="M526.8,850c-104.9,0-203.4-44.3-277.4-124.7c-73.3-79.6-115.3-186.2-118.2-300.2c-2.1-77.2,27.3-152,81.5-207
                                            c50.1-51.4,116.1-80.9,181.3-80.9c12.9,0,25.8,1.1,38.5,3.3l5.5,1l-4.9,2.3C337.4,191,262.6,314.6,262.6,425
                                            c0,156.7,118.6,284.2,264.4,284.2c111.8,0,214.8-79.5,250.4-193.3l0.4-1.3h135.8l-0.4,2.2C873.6,709.9,711.1,850,526.8,850z
                                            M394,140.7c-64.2,0-129.3,29.1-178.8,79.8c-53.5,54.4-82.6,128.2-80.5,204.5c2.9,113.1,44.6,218.9,117.3,297.8
                                            c73.3,79.7,170.9,123.5,274.8,123.5c181.9,0,342.3-137.8,382.2-328H780.4C743.9,632.9,639.9,712.9,527,712.9
                                            c-147.8,0-268-129.1-268-287.9c0-109.7,73.2-232.3,167.5-281.9C415.7,141.5,404.9,140.7,394,140.7L394,140.7z"  fill={darkMode == true ? "#000" : "#fff"} />
                                        <path className="st1 path4" ref={c => this.sta2 = c} d="M921.5,335.5H788l-0.3-1.4c-21.2-98.5-71.6-182.4-145.9-242.8c-68.1-55.4-151.6-86.5-235-87.6h-5.6V0.1
                                            C535-2.2,655.8,29.7,750.4,92.3c90.2,59.8,150.9,145.4,170.7,241L921.5,335.5z M791,331.8h126C896.9,238,837.1,154.1,748.3,95.3
                                            C668.9,42.7,570.8,11.9,462,5.1c66.8,11.6,129.5,40.4,182,83.4C718.7,149,769.4,233.2,791,331.8z"  fill={darkMode == true ? "#000" : "#fff"} />
                                        <path className="st1 path2" ref={c => this.sta3 = c} d="M525.9,712.8c-10.5,0.2-21-0.5-31.4-2l-5.6-1l5.2-2.5c63.6-30.9,130.3-113.2,155.1-191.4l0.4-1.3h131.9
                                            l-0.7,2.3c-39,127.3-154.2,194.9-247.8,195.8L525.9,712.8z M500.8,707.9c10.7,1.1,21.4,1.5,32.1,1.2
                                            c91.7-0.9,204.4-66.7,243.6-190.8H652.2C627.5,594.8,563.2,675,500.8,707.9z"  fill={darkMode == true ? "#000" : "#fff"} />
                                        <path className="st1 path3" ref={c => this.sta4 = c} d="M524.6,850h-5H400.3C179.6,850,0,659.4,0,425.1S179.6,0.3,400.3,0.3c186.5-0.2,351,139.9,390.9,332.9l0.4,2.3
                                            H654l-0.4-1.3c-36.5-114.4-147.2-195.8-263.2-193.4c-67.1,1.3-133.4,33.1-182,87.1c-52.8,58.5-79.2,136.3-72.8,214.9
                                            C150.2,654.5,288.1,789.9,418.3,830c32.9,10.1,67,15.5,101.4,16.3h12.2v3.6L524.6,850L524.6,850z M400.3,3.7
                                            C181.6,3.7,3.7,192.7,3.7,425s177.9,421.3,396.6,421.3h74.3c-19.4-2.9-38.6-7.2-57.4-12.8C285.7,793,146.5,656.6,131.9,443
                                            c-6.4-79.6,20.3-158.3,73.8-217.6c49.3-54.8,116.6-87,184.7-88.2c117.1-2.3,228.9,79.5,266.3,194.7h130.5
                                            C746.7,141.5,584.4,3.7,400.3,3.7z"  fill={darkMode == true ? "#000" : "#fff"} />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        {imgUrl &&
                            <div className="bgAnimation" style={{backgroundImage: `url(${imgUrl})` }} ></div>
                        }
                        {bgColor &&
                            <div className="bgAnimation" style={{ backgroundColor: `${bgColor}` }} ></div>
                        }
                    </div>
                {children}
            </React.Fragment>
        );
    }
}

export default AnimationLogo;