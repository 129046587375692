import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ControlsBoxBlack from '../../../components/ControlsBox/ControlsBoxBlack';
import ControlsBoxPink from '../../../components/ControlsBox/ControlsBoxPink';
import LevelSelector from '../../../components/Leasing/LevelSelector';
import { Helmet } from "react-helmet";



class Leasing extends Component {
    state= {
        activeMap: null,
        floorActive: 0,
        imagesFilters : [],
        nFloors: 0,
        open: false,
    }

    componentDidMount = () => this.onInit(this.props);
    onInit = props => {
        if (  props._location.Leasing && props._location.Leasing !== null && props._location.Leasing.CentreMaps.length > 0){
            if(this.state.activeMap == null) {
                this.setState({ activeMap: props._location.Leasing.CentreMaps[0] });
            }
        }
    }

    show = dimmer => () => {
        this.setState({ dimmer, open: true });
    }
    close = () => this.setState({ open: false })

    _toogleFullScreen = () => {
        this.setState({ open: !this.state.open })
    }

    selectFloorHandler = (floorName, i) => {
        this.setState({imagesFilters: []})
        var p  = (this.props._location.Leasing.CentreMaps.find(({ FloorName }) => FloorName === floorName));
        if (p !== undefined){
            this.setState(  {
                activeMap: p
            });
        }
        this.setState({  floorActive: i  });
    }
    

    _handleControllerLevels = (isNext) => {
        var nFloors = this.props._location.Leasing.CentreMaps.length, i=0;
        i= this.state.floorActive;

        this.setState({imagesFilters: []})
  
        if(isNext){
            if (i + 1 < nFloors) {
                i += 1;
            } else {
                i = 0;
            }
      
        }else{
            if (i - 1 >= 0) {
                i -= 1;
            } else {
                i = nFloors - 1;
            }
        }
      
        this.selectFloorHandler(this.props._location.Leasing.CentreMaps[i].FloorName, i);
    }

    _handleDisplayFilterImages = (img) => {
        if(this.state.imagesFilters.indexOf(img) === -1) {
            this.setState({imagesFilters: [...this.state.imagesFilters, img]});
        }else{
            this.setState({imagesFilters:this.state.imagesFilters.filter(function(item) { 
                return item !== img
            })});
        }
    }

    render() {
        const { match, _location } = this.props;
        const { open, floorActive, imagesFilters} = this.state;

        var getCenterMap;
        if (_location.Leasing != null ) {
            getCenterMap = _location.Leasing.CentreMaps.map((centerMap, i) => {
                return <li key={i} onClick={() => this.selectFloorHandler(centerMap.FloorName, i)}>
                    <div className={`LevelSelectorWrapper  ${centerMap.FloorName}`}><LevelSelector isActive={this.state.floorActive === i} LeadColour={_location.LeadColour} /></div>
                </li>
            });
        }

        return (
            <React.Fragment>
                <div className={`grid-head black full-height ${ !open ? '' : 'hidden' }`} >
                    <ControlsBoxBlack >
                            <div >
                                <div className="Type" >
                                    <div className="LocationTypesWrapper" style={{ color: _location.LeadColour, marginBottom:"10px", marginLeft:"-4px" }}>
                                        {_location.LocationTypes && 
                                            _location.LocationTypes.map((index,key) => {
                                                return(
                                                    <span className="LocationTypes" key={key}> 
                                                        {index.Name} 
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="Name">{_location.Name}</div>   
                                <div className="Address">{_location.Address}</div> 
                            </div>
                            <div>
                                { window.map_url &&
                                    <Link to={ window.map_url} ><div className="dark-btn l rto-btn leasing"> Back </div></Link>
                                }
                            </div>  
                    </ControlsBoxBlack>
                    
                    <ControlsBoxPink match={match} location={_location}>
                        <div>
                            {_location.Leasing && _location.Leasing.ContactInfo &&
                                (_location.Leasing.ContactInfo.Name !== null && _location.Leasing.ContactInfo.Name.trim() !== "") &&
                                <div>
                                    <div className="headerContact">
                                        <h1>Connect with...</h1>
                                        <h1>{ `${_location.Leasing.ContactInfo.Name}`}</h1>
                                        <h4>{`${_location.Leasing.ContactInfo.JobTitle}`}</h4>
                                    </div>
                                    <div>
                                        <ul className="listContacts">
                                            {(_location.Leasing.ContactInfo.Telephone !== null && _location.Leasing.ContactInfo.Telephone.trim() !== "") !== null && 
                                                <li className="telephone"><a href={`tel:${_location.Leasing.ContactInfo.Telephone}`}>{_location.Leasing.ContactInfo.Telephone}</a> </li> 
                                            }

                                            {_location.Leasing.ContactInfo.Email !== null && 
                                                <li className="email"><a href={`mailto:${_location.Leasing.ContactInfo.Email}`}>{_location.Leasing.ContactInfo.Email}</a></li>
                                            }

                                            {_location.Leasing.Brochure !== null && 
                                                <li className="download">  <a href={`${_location.Leasing.Brochure.Url}`} target="_blank" download>{_location.Leasing.Brochure.Name}</a></li> 
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }

                            <div style={{marginTop: "50px"}}>
                                <Link  to={{ pathname: `/locations/${this.props.match.params.id}/overview`   , state: { map_url: this.props.location.state ?  this.props.location.state.map_url : false}  }}><div className="dark-btn l"> Return to overview </div></Link>
                            </div>
                        </div>
                    </ControlsBoxPink>
                </div>
                <section id="leasing" className={`${ !open ? '' : 'full' }`}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Landsec Connect | Locations |  Leasing</title>
                    </Helmet>

                    <div className="leasing-wrapper">
                        <div>
                            <div className={`mapImage ${this.state.activeMap && this.state.activeMap.FullScreen ? "FullScreen" : ""}`}  style={{backgroundImage : `url(${this.state.activeMap !== null && this.state.activeMap.Image !== null ? this.state.activeMap.Image.Url : ""})`}} onClick={this._toogleFullScreen} />
                            {imagesFilters.map((item, index) => {
                                return( <div key={index} className={`mapImage ${ this.state.activeMap && this.state.activeMap.FullScreen ? "FullScreen" : ""}`}  style={{backgroundImage : `url(${item !== null && item !== null ? item : ""})`}} onClick={this._toogleFullScreen} /> )
                            })}
                        </div> 

                        {/* Render Levels Selector */}
                        <div className="LevelSelectorPanelWrapper">

                            {_location.Leasing && _location.Leasing.CentreMaps && _location.Leasing.CentreMaps.length > 1 &&
                            <div className="controlsLevelSelector" onClick={() => this._handleControllerLevels(false)}>{"<"}</div>
                            }

                            {_location.Leasing && _location.Leasing.CentreMaps && _location.Leasing.CentreMaps.length > 1 &&
                                 <div className="FloorNameLabel">{this.state.activeMap !== null ? `${this.state.activeMap.FloorName}` : null}</div>
                            }

                            {_location.Leasing && _location.Leasing.CentreMaps && _location.Leasing.CentreMaps.length > 1 &&
                                <ul id="ListLevelSelector">{getCenterMap}</ul>
                            }
                            
                            {_location.Leasing && _location.Leasing.CentreMaps && _location.Leasing.CentreMaps.length > 1 &&
                                <div className="controlsLevelSelector" onClick={() => this._handleControllerLevels(true)}>{">"}</div>
                            }
                        </div>

                        {/*Text Contact */}
                        <div className="topContent">
                        <h1> { this.state.activeMap !== null ?  `${this.state.activeMap.Name}` : null }</h1>
                        </div>
                        <div className="bottomContent" >
                            {  _location.Leasing && _location.Leasing.CentreMaps && _location.Leasing.CentreMaps.length > 0 &&
                                <React.Fragment >
                                    <div>
                                        <ul className="MapFiltered-subMenu">
                                            {
                                                _location.Leasing.CentreMaps[floorActive].ConnectionOverlays &&
                                                _location.Leasing.CentreMaps[floorActive].ConnectionOverlays.map((item, index)=> {
                                                return(
                                                    <li key={index} >
                                                        <label className="cr" key={index} >
                                                            <span>
                                                                <input type="checkbox" /> 
                                                                <span> <i className="checkbox"></i></span> 
                                                                <span className="text" onClick={() => this._handleDisplayFilterImages(item.Image.Url )}> {item.Name}</span> 
                                                            </span>
                                                        </label>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            }
                        </div> 
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Leasing;