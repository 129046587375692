import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchMapFilteredLeasing } from "../../webService/actions/mapFilteredLeasing"
import { Helmet } from "react-helmet";
import MapFiltered from './MapFiltered';

class MapFilteredLeasing extends Component{
    state = {
        filterTag: "",
        checkboxChanged: ""
    }

    componentDidMount = () => this.onInit(this.props);
    
    onInit = props => { 
        props.fetchMapFilteredLeasing();  
    }

    render () {
        const { locations, match } = this.props;
        
        return (
            <React.Fragment >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Map View - Leasing</title>
                </Helmet>
                {locations &&
                    <MapFiltered id={match.params.id} arrayFilter={locations.Filter} title={"Leasing"} locations={locations} isLeasing={true}  match={match}/>
                }
            </React.Fragment>
        );
    }
}

MapFilteredLeasing.propTypes = {
    fetchMapFilteredLeasing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        locations: state.mapFilteredLeasing
    }
}

export default connect(mapStateToProps, { fetchMapFilteredLeasing })(MapFilteredLeasing);