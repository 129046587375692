import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Redux
import { connect } from "react-redux";
import { fetchNavigator } from "../../webService/actions/navigator"

import PropTypes from 'prop-types';

//Icons
//import iconMenuConnect from '../../assets/components/navigator/Icon_Menu_Connect.svg'
//import iconMenuExperience from '../../assets/components/navigator/Icon_Menu_Experience.svg'
//import iconMenuOpportunities from '../../assets/components/navigator/Icon_Menu_Opportunities.svg'


class Navigator extends Component {
    state = {
        navigator: []
    }


    componentDidMount = () => this.onInit(this.props);
    onInit = props => {
        props.fetchNavigator();
    }
    
    _checkIfActualPath = (el) => {
        var pathArray = this.props.pathname.split('/');
        return pathArray[1].includes(el.Link.split('/')[1].toLowerCase())
    }

    _renderNav = (nav, i) => {
        return <Link to={nav.Link} key={i} className={`nav-r-item ${this._checkIfActualPath(nav) ? 'active' : ''} `} > <div className="nav-r-item-logo"> <img className="logo-img-nav" src={nav.Icon.Url} alt={"logo"} /> </div> <div className="nav-r-text"> {nav.DisplayText} </div></Link >
    }

    render() {
        const { navigator  } = this.props;

        
        return (
            <React.Fragment> 
                {navigator ?
                    
                    navigator.map(this._renderNav)
                    :
                    <div>Loading...</div>
                    
                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        navigator: state.navigator.data
    }
}

export default connect(mapStateToProps, { fetchNavigator })(Navigator);