import { GET_LOCATIONS } from "../types"

const initialState = { }
   
    

export default function locations(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LOCATIONS:{
            return {
                ...state,
                ...action.data
            }
        }
        default:
            return state;
    }
}