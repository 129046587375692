import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './ControlsBox.css';
import logo from '../../assets/logo.svg'
import logo_Mobile from '../../assets/logo_mobile.svg'
import logo_dark from '../../assets/logo_dark.svg'
import logo_Mobile_dark from '../../assets/logo_mobile_dark.svg'
import logo_LC2 from '../../assets/LC-2.gif'
import logo_Landsec from '../../assets/logo_landsec.svg'
import logo_Landsec_dark from '../../assets/logo_landsec_dark.svg'


class ControlsBoxLogo extends Component {

    _RenderLogo = () => {
        return (
            <React.Fragment>
                {/* <img id={`logo-img`} className={` ${ true ? 'noDelay' : ''}`} src={ logo } alt={"logo"} />
                <img id={`logo-img-landsec`} className={` ${ true ? 'noDelay' : ''}`} src={true ? logo_Landsec : logo_Landsec_dark} alt={"logo"} /> */}
                <img id={`logo-img-landsec-2`}  src={logo_LC2} alt={"logo"} />  
                <img id="logo-img-mobile" src={true ? logo_Mobile : logo_Mobile_dark} alt={"logo"} />
            </React.Fragment>
        )
    }

    render() {
        const {children, color, subText, isDark = false, noBg= false, noLink = false} = this.props;
        return (
            <div className="grid-head-container cbl">
            <div className="grid-head-body-black" style={{ backgroundColor: (isDark || noBg ? 'rgba(0,0,0,0)' : '') }}>
                <div className="ghb-wrapper-black" style={{ backgroundColor: (isDark || noBg ? 'rgba(0,0,0,0)' : '') }}>
                    <div className="x-logo-wrapper">

                        {noLink ?
                                this._RenderLogo()
                        :

                            <Link to={`/`}> {this._RenderLogo()}</Link>

                        }

                       
                    </div>
                </div>
                <div className="ghb-wrapper-black-text cbl">
                    <div>
                        <div className="small" style={{ color: color}}>{subText}</div>
                        <div className="Name">{children}</div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ControlsBoxLogo;