export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const GET_NAVIGATOR = 'GET_NAVIGATOR';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATION = 'GET_LOCATION';
export const GET_EVENT_SPACE = 'GET_EVENT_SPACE';
export const GET_HOMEPAGE = 'GET_HOMEPAGE'; 
export const GET_OPPORTUNITY_LANDING_PAGE = 'GET_OPPORTUNITY_LANDING_PAGE'; 
export const GET_OPPORTUNITY_PAGE = 'GET_OPPORTUNITY_PAGE'; 
export const GET_CONTACT_US_PAGE = 'GET_CONTACT_US_PAGE'; 
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY'; 
export const GET_TEXT_PAGE = 'GET_TEXT_PAGE'; 
export const GET_LEASING_PAGE = 'GET_LEASING_PAGE'; 
export const GET_CONNECTIONS_PAGE = 'GET_CONNECTIONS_PAGE'; 
export const MAP_FILTERED_LEASING = 'MAP_FILTERED_LEASING'; 
export const MAP_FILTERED_CONNECTIONS = 'MAP_FILTERED_CONNECTIONS'; 
export const GET_CONNECTIONS_DETAIL = 'GET_CONNECTIONS_DETAIL';


