import { GET_LEASING_PAGE } from "../types"

const initialState = {
    data: [
    
    ]
}

export default function leasingPage(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LEASING_PAGE:{
            return {
                ...state,
                ...action,
            }
        }
        default:
            return state;
    }
}