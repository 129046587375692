import { GET_OPPORTUNITY_PAGE } from "../types"

const initialState = {}

export default function opportunityPage(state = initialState, action = {}) {
    // console.log("GET_OPPORTUNITY_PAGE", action)
    switch (action.type) {
        case GET_OPPORTUNITY_PAGE:{
            return {
                ...state,
                ...action.data,
            }
        }
        default:
            return state;
    }
}