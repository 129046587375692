import React from 'react';
import { Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const MapBoxRow = (props) => {
    const _renderKeyFacts = (keyfact, index) => {
        return (
            <Grid.Row className="center-detail-wrapper" key={"_renderKeyFacts-" + index}>
                <Grid.Column >
                    <div className="center-detail-row">
                        <div className="center-detail-icon-bg" style={{ backgroundImage: `url(${keyfact.Icon.Url})` }} />
                        <div className="center-detail-body">
                            <div className="center-detail-numb">{keyfact.Name}</div>
                            <div className="center-detail-desc">{keyfact.Value}</div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        )
    }

    const transformedLocation = props.locations && 
        props.locations.map((city, i) => {
            // let filteredLocations = city.FilterableItems ? city.FilterableItems.filter(item => item.Id === props.filterTag) : 0;
            let filteredLocations = city.FilterableItems ?  props.instersactionHelper(city.FilterableItems,  props.filterTag) : 0;
            
            return (filteredLocations.length > 0 || props.filterTag.length == 0)  &&
                    <Link to={city.Link ? city.Link : "#"} key={i} className="center-detail-container-row">
                        <Grid  divided='vertically' key={'loc-' + i}>
                                <Grid.Row columns={2}>
                                    <Grid.Column className="center-detail-center-name">
                                        {city.Name}
                                    </Grid.Column>
                                    <Grid.Column className="center-detail-column">
                                        <Grid>
                                    {city.MapKeyFacts &&
                                        city.MapKeyFacts.map(_renderKeyFacts)}
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                    </Link>
        });
        
    return (
        <div className="center-detail-container-row">
            { transformedLocation }
        </div>
    );
};

export default MapBoxRow;