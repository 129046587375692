import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TooltipLocation extends Component {
    _renderKeyFact = (keyFact, index) => {
        return (
            <div className="specItem" key={index}>
                <div className="specIcon" style={{ backgroundImage: `url(${keyFact.Icon.Url})` }}   /> 
                <div className={`keyFactsTextWrapper ${this.props.Link ? '' :'noLinkTrough'}`}>
                    <div className="keyFactsTitle">{keyFact.Name}</div>
                    <div className="keyFactsText">{keyFact.Value}</div>
                </div>
            </div>
        );
    }

    render() {
        const { MapThumbnailImage, isFull, MapKeyFacts = null, Name, isLeasing, Id, filterTag, match} = this.props;
      
        const linkTo = `/locations/${Id}/` + (isLeasing ?  `overview` :  `leasing`);
        window.map_url = match ? match.url : false;

        return  (
            
                <div className="toolTipLocationWrapper">
                {/* + "/"+filterTag */}
                    <Link to={!isFull ? { pathname: this.props.Link ? this.props.Link   : '#'  } : "#"} >
                        <div className="toolTipLocationContainer">
                        <div className="LocationImg" style={ { backgroundImage: `url(${MapThumbnailImage ? MapThumbnailImage.Url : ""})` } } />
                            <div className="LocationDesc" >
                                <div className="title">{Name}</div>
                                <div className="listOfSpec">
                                    { MapKeyFacts !== null &&
                                        MapKeyFacts.map(this._renderKeyFact) 
                                    }
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                
        ) 
    }
}

export default TooltipLocation;

