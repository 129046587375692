import { GET_EVENT_SPACE } from "../types"

const initialState = {}

export default function location(state = initialState, action = {}) {
    switch (action.type) {
        case GET_EVENT_SPACE:{
            return {
                ...state,
                ...action.data,
            }
        }
        default:
            return state;
    }
}