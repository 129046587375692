import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 

import './ControlsBox.css';
// import { Dropdown } from 'semantic-ui-react'
import Menu from '../Location/Menu'


class ControlsBox extends Component {
    friendOptions = [
        {
            text: <Link to={`${this.props.match.url}/overview`}> Overview  </Link>,
            value: 'Overview' ,
        },
        {
            text: <Link to={`${this.props.match.url}/keyfacts`}> Key Facts </Link>,
            value: 'keyFacts',
        },
        {
            text: <Link to={`${this.props.match.url}/opportunities`}> Opportunities </Link>,
            value: 'opportunities',
        },
        {
            text: <Link to={`${this.props.match.url}/leasing`}> Leasing </Link>,
            value: 'leasing',
        },
        {
            text: <Link to={`${this.props.match.url}/gallery`}> Gallery </Link>,
            value: 'gallery',
        },
    ];

    render() {
    const { location, children} = this.props;
        return (
            <div className="grid-head-container">
                <div className="grid-head-body-pink ghb" style={{backgroundColor: location.LeadColour}}>
                    <div className="ghb-wrapper-pink-text">

                        {children}
                      
                    </div>
                </div>
            </div>
        );
    }
}

export default ControlsBox;

