import { GET_NAVIGATOR } from "../types"


const initialState = {}

export default function navigator(state = initialState, action = {}) {
 
    switch (action.type) {
        case GET_NAVIGATOR:
            return {
                ...state,
                ...action
            }
        default:
            return state;
    }
}