import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'; 


class Menu extends Component {
    state = {
        open: false
    }

    _toggleMenu = () =>{
        this.setState({open: !this.state.open});
    }

    render() {
        const { match, location } = this.props;


        return (
            <div>
                <ul className="listNav">

                    {true &&
                        <li><NavLink to={`${match.url}/overview`} activeClassName="active" className={match.isExact == true ? "active" : ""}>Overview</NavLink></li>
                    }

                    {location.KeyFacts &&
                        <li><NavLink to={`${match.url}/keyfacts`} activeClassName="active" >Key Facts</NavLink></li>
                    }

                    {location.Leasing &&
                        <li><NavLink to={`${match.url}/leasing`} activeClassName="active" >Leasing Plan</NavLink ></li>
                    }

                    {location.OpportunityTypes &&
                        <li><NavLink to={`${match.url}/opportunities`} activeClassName="active" >Opportunities</NavLink></li>
                    }

                    {(location.GalleryItems && location.GalleryItems[0].Images) &&
                        <li><NavLink to={`${match.url}/gallery`} activeClassName="active" >Gallery</NavLink></li>
                    }
                </ul>
                <div className={`JustMobile listNavMobileWrapper ${this.state.open ? 'open' : 'close'}`} onClick={this._toggleMenu}>
                    <ul className={`listNavMobile`} >
                        {true &&
                            <li><NavLink to={`${match.url}/overview`} activeClassName="active" className={match.isExact == true ? "active" : ""}>Overview</NavLink></li>
                        }

                        {location.KeyFacts &&
                            <li><NavLink to={`${match.url}/keyfacts`} activeClassName="active" >Key Facts</NavLink></li>
                        }

                        {location.Leasing &&
                            <li><NavLink to={`${match.url}/leasing`} activeClassName="active" >Leasing Plan</NavLink ></li>
                        }

                        {location.OpportunityTypes &&
                            <li><NavLink to={`${match.url}/opportunities`} activeClassName="active" >Opportunities</NavLink></li>
                        }

                        {(location.GalleryItems && location.GalleryItems[0].Images) &&
                            <li><NavLink to={`${match.url}/gallery`} activeClassName="active" >Gallery</NavLink></li>
                        }
                    </ul>
                </div>
            </div>

        );
    }
}


export default Menu;