import React, { Component } from 'react';
import MapBox from '../../components/CentresLocations/MapBox/MapBox';
import ControlsBoxBL from '../../components/ControlsBox/ControlsBoxBL';
import ControlsBoxTL from '../../components/ControlsBox/ControlsBoxTL';
import MapFiltered from './MapFiltered.css';

class LocationBuilder extends Component{
    state = {
        filterTag: [],
        checkboxChanged: ""
    }

    componentDidMount = () => this.onInit(this.props);
    
    onInit = props => { 
        this.setFilterHandler();
    }

    setFilterHandler = props => {
        if(window.filterTag){
            this.setState( {
                filterTag: [...this.state.filterTag, ...window.filterTag]
            })
        }else{
            this.filterChangeHandler(this.props.id)
        }
    }

    filterChangeHandler = (filterTag) => {
        if(!this.filterCheckIfExistHandler(filterTag)){
            this.setState( {
                filterTag: [...this.state.filterTag, filterTag]
            })
        }else{
            this.setState({
                filterTag: this.state.filterTag.filter( i => i !== filterTag)
            });
        }
    }

    filterCheckIfExistHandler = (filterTag) => {
        return this.state.filterTag.includes(filterTag)
    }

    render () {
        const { locations, isLeasing, title, match } = this.props;
        return (
            <React.Fragment >
                <div className="grid-head black full-height">  
                    <ControlsBoxTL> { title } </ControlsBoxTL>
                    <ControlsBoxBL className="MapFiltered-container" backgroundColor="white">
                        <div className="MapFiltered-menu" >
                             <li className="active">
                                {
                                    locations.Filter &&
                                        <React.Fragment>
                                            <div className="FilterName" style={{color:"black"}}>{locations.Filter[0].Name}</div>
                                            <ul className="MapFiltered-subMenu ">
                                                { locations.Filter[0].FilterableItems &&
                                                    locations.Filter[0].FilterableItems.map((item, index) => {
                                                        return (
                                                            <li key={index} >
                                                                <label className="cr"  > 
                                                                    <span>
                                                                        <input type="checkbox" onClick={ () => {this.filterChangeHandler(item.Id)}} checked={this.filterCheckIfExistHandler(item.Id) } onChange={()=>{}}  /> 
                                                                        <span> 
                                                                            <i className="checkbox"></i>
                                                                        </span> 
                                                                        <span className="text">
                                                                            <span className="dots" style={{ color: item.Colour }}>.</span>
                                                                            {item.Name}
                                                                        </span> 
                                                                    </span>
                                                                </label>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </React.Fragment>
                                }
                            </li>
                        </div>
                    </ControlsBoxBL>  
                </div>
                 <MapBox isFull={false} locations={locations.LocationList} filterTag={this.state.filterTag || []} isLeasing={isLeasing} setFilterHandler={(props)=>this.setFilterHandler(props)} match={match} />
            </React.Fragment>
        );
    }
}

export default (LocationBuilder);