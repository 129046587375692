import { GET_LOCATION } from "../types"

const initialState = {}


export default function location(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LOCATION:{
            return {
                ...state,
                ...action.data
            }
        }
        default:
            return state;
    }
}