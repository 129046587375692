import React, { Component } from 'react';
import ControlsBoxBlack from '../../../components/ControlsBox/ControlsBoxBlack';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Carousel } from 'react-responsive-carousel';
class Overview extends Component {
    _renderTest = (KeyFacts) => {
        return (
            KeyFacts.length > 0 ?
                <div>
                    <div className="centre-specific-page-detailsWrapper"> 
                        {KeyFacts.map((item, index) => {
                            return (
                                <label key={index} className={`centre-specific-page-keyfact-wrapper ${item.KeyFactDetails.length > 0 ? "active" : ""}`}>
                                    <input type="checkbox" className="centre-specific-page-keyfact-controls" />
                                    <div>
                                        <div className="centre-specific-page-keyfact-icon" style={{backgroundImage : `url(${ item.Icon.Url })`}}></div>
                                        <div className="centre-specific-page-keyfact-title">{item.Value}</div>
                                        <div className="centre-specific-page-keyfact-subtitle">{item.Name}</div>
                                    
                                        <div className="centre-specific-page-keyfact-details">
                                            {item.KeyFactDetails.map((item,index) => {
                                            return(
                                                    <div key={index}>
                                                        <div className="centre-specific-page-keyfact-details-heading">{item.Heading}</div>
                                                        <div className="centre-specific-page-keyfact-details-text">{item.Text}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </label>
                            )
                        })}
                    </div>
                </div>
            :
           KeyFacts.map(() => {})
        )
    }
    
    render() {
        const { match, _location } = this.props;

        return (

            <React.Fragment>
                 <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Locations |  Overview</title>
                </Helmet>

                <div className="grid-head black">
                    <ControlsBoxBlack >
                        <div>
                            <div className="Type" >
                                <div className="LocationTypesWrapper" style={{ color: _location.LeadColour, marginBottom:"10px", marginLeft:"-4px" }}>
                                    {_location.LocationTypes && 
                                        _location.LocationTypes.map((index,key) => {
                                            return(
                                                <span className="LocationTypes" key={key}> 
                                                    {index.Name} 
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="Name">{_location.Name}</div>   
                            <div className="Address">{_location.Address}</div>  
                            
                            { window.map_url &&
                                <Link to={ window.map_url} ><div className="dark-btn l rto-btn"> Back </div></Link>
                            } 
                        </div>
                    </ControlsBoxBlack>
                </div>

                <section id="centre-specific-page">
                    <div className="grid-item-b grid-item-jolly"></div>
                    <div className=" grid-item-b grid-item-3-2 overview-block-01" style={ _location.LeadImage && { backgroundImage: `url(${ _location.LeadImage.Url })` }} />
                    <div className="grid-item-b grid-item-2-2 ModelImage leasing-map-wrapper overview-block-02"  style={ _location.LeadImage && { backgroundImage: `url(${ _location.ModelImage ? _location.ModelImage.Url : "" })` }} >
                        <Link  to={{ pathname: `/locations/${this.props.match.params.id}/leasing`   , state: { map_url: this.props.location.state ?  this.props.location.state.map_url : false}  }}><div className="dark-btn r leasing-map-btn"> Leasing Plan </div></Link>
                    </div>
                    <div className="grid-item-b grid-item-2-2 centre-specific-page-details-container overview-block-03"  style={{ background : _location.LeadColour }}>  
                            <Carousel className="SliderContainer" height="100%" showArrows={true} showThumbs={false} showStatus={false} showIndicators={false} swipeable={true} emulateTouch={true} infiniteLoop={true} autoPlay={true} >
                                <div className="Slider-Flex">
                                    <div className="centre-specific-page-detailsWrapper"> 
                                        {_location.Description} 
                                    </div>
                                </div>

                                {
                                    _location.KeyFacts  ? 
      
                                    this._renderTest(_location.KeyFacts)
                             
                                    :
                                    null
                                }
                           
                                { _location.CaseStudies ?
                                    _location.CaseStudies.map((item, index) => {
                                        return(
                                            <div className="case-studies-wrapper" key={index}>
                                                    <div className="case-studies-wrapper-bg" style={{backgroundImage: `url(${item.Image.Url})`}}></div>
                                                    <div className="case-studies-wrapper-content">
                                                        <div className="case-studies-wrapper-content-name">{item.Name}</div>
                                                        <div className="case-studies-wrapper-content-logo" style={{backgroundImage : `url(${item.Logo.Url})`}}></div>
                                                        <div className="case-studies-wrapper-content-summary">{item.Summary}</div>
                                                    </div>
                                            </div>
                                        )
                                    })

                                    :
                                     null
                                }

                            </Carousel>
                    </div>

                </section>
            </React.Fragment>
        );
    }
}

export default Overview;