import { GET_HOMEPAGE } from "../types"

const initialState = {
}

export default function homepage(state = initialState, action = {}) {
    switch (action.type) {
        case GET_HOMEPAGE:{
            return {
                ...state,
                ...action.data,
            }
        }
        default:
            return state;
    }
}