import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

//Redux
import { connect } from "react-redux";
import { fetchLocations } from "../../webService/actions/locations"

import ControlsBox from '../../components/ControlsBox/ControlsBox';
import CentresBox from '../../components/CentresLocations/CentresBox/CentresBox';
import MapBox from '../../components/CentresLocations/MapBox/MapBox';

// import AnimationLogo from '../../components/Animations/AnimationLogo'
// import { Button, Modal } from 'semantic-ui-react'
// import { Player } from 'video-react';
// import VideoCover from '../../components/Videos/VideoCover';

import { Helmet } from "react-helmet";

class LocationBuilder extends Component{

    componentDidMount = () => this.onInit(this.props);
    onInit = props => { 
        props.fetchLocations();
    }

    render () {
        const { locations, match  } = this.props;
        const mapId = this.props.match.params.id;
        const isMap = mapId !== "map";
        let locationsBox;

        if (isMap) {
            locationsBox = <CentresBox locations={locations.LocationList} match={match}/>;
        } else {
            locationsBox = <MapBox locations={locations.LocationList} />;
        }

        return (
            <React.Fragment >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Locations</title>
                </Helmet>
                <ControlsBox isMap={isMap} {...locations}>
                    {isMap 
                        ?
                        <div className="six columns"><Link to="/locations/map"><i aria-hidden="true" className="caret right big icon"></i> {locations.ViewLocationText} </Link></div>
                        :
                        <div className="six columns"><Link to="/locations"><i aria-hidden="true" className="caret right big icon"></i> {locations.ViewGridText} </Link></div>
                    }
                </ControlsBox>

                {locationsBox}
            </React.Fragment>
        );
    }
}

LocationBuilder.propTypes = {
    fetchLocations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        locations: state.locations
    }
}

export default connect(mapStateToProps, { fetchLocations })(LocationBuilder);