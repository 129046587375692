import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchTextPage } from "../../webService/actions/textPage"
import './ConnectBuilder.css';

class TextPageBuilder extends Component {
    constructor(props) {
        super(props);
        //Here ya go
        this.props.history.listen((location, action) => {
            document.body.classList.remove('dark');
        });
    }

    componentDidMount() {
        this.onInit(this.props);
    }

   onInit = props => {
        if (this.props.match.params.id)
            props.fetchTextPage(this.props.match.params.id);
    }
    
    render() {
        const { textPage } = this.props;
        return (
            <React.Fragment>
                <section id="TextPage">
                        <div className="textPageWrapper">
                            <div>
                                <h2 className="textPageWrapper-Title">{textPage.Title}</h2>
                                <div dangerouslySetInnerHTML={{ __html: textPage.Html }}></div>
                            </div>
                        </div>
                </section>
            </React.Fragment>
        );
    }
}

TextPageBuilder.propTypes = {};
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, { fetchTextPage })(TextPageBuilder);