import React, { Component } from 'react';

import Player from '@vimeo/player';
import './VideoCover.css'

class VideoCover extends Component {

    componentDidMount() {
        this.onInit(this.props);
    }

    onInit = props => {
        const { Src, isFixed = true, isVimeo = true } = props;
        if (isVimeo) {
            var player = new Player(this.iframeVimeo);

            player.on('bufferend', function () {
                //   console.log("Video - Buffered!")
                this.props.eventVideoBufferedHandler()
            }.bind(this));
        }
      
    }

    _DefaultPlayer = (props) => {
        return <div dangerouslySetInnerHTML={{
                        __html: `
                <video
                id="myDefaultVideo"
                muted
                autoplay
                loop
                playsinline
                >
                     <source src="${props.Src}" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            ` }}
        />

   
    }

    _vimeoPlayer = (props) => {
        return <div className={`iframe-wrapper ${props.isFixed ? 'fixed' : 'absolute'}`}>
            <iframe ref={c => this.iframeVimeo = c} src={props.Src} />
        </div>
    }

    render() {
        const { Src, isFixed = true, isVimeo= true} = this.props
        return (
            
                isVimeo == true ?
                    this._vimeoPlayer({ isFixed, isVimeo, Src })
                :
                    this._DefaultPlayer({ isFixed, isVimeo, Src  })
           
        );
    }
}



export default VideoCover;