import React from 'react';
import ReactDOM from 'react-dom';
//import CSSModules from 'react-css-modules';
import { BrowserRouter } from 'react-router-dom';
//Integration Redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './webService/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import ScrollToTop from './components/HOC/ScrollToTop'

import App from './App';
import * as serviceWorker from './serviceWorker';
import 'babel-polyfill';


import './index.css';
import 'semantic-ui-css/semantic.min.css';
//Test

//Creation Store Redux
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();

