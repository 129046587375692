import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './FlipBox.css';

class FlipBox extends Component {
    state= {
        isActive: false
    }

    _handleClick = () => {
        this.setState({ isActive : true })
        setTimeout(() => {
            this.setState({ isActive: false })
        }, 2500);
    }


    render() {
        const {match, image, name, description, to, leadColour = "#daa404" } = this.props;

        return (
            <div className={`FlipBoxWrapper ${this.state.isActive ? 'active' : ''}`} to={to} style={{ cursor: "auto" }}>
                <Link className={`opportunity-block`} to={{ pathname: to  , state: { isComingFromConnection: true, url: match.url}  }} style={{ cursor: "auto" }} onClick={() => this._handleClick()}>
                    <div className="Front" style={{ backgroundImage: `url(${image})` }} >
                    
                        <div className="grid-item-content active"><span className="grid-item-symbol arrow"> </span><span className="grid-item-title small">{name}</span></div>
                    </div>
                    <div className="Back" style={{ backgroundColor: leadColour }}>
                        <div className="BackWrapper">
                            <div className="title"> <h2>{name}  </h2></div>
                            <div className="text">
                                { description }
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default FlipBox;