import React, { Component } from 'react';
import ControlsBoxBlack from '../../../components/ControlsBox/ControlsBoxBlack';
import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

//Redux
import { connect } from "react-redux";
import { fetchConnectionsDetail } from "../../../webService/actions/connectionsDetail"

import MapBox from '../../../components/CentresLocations/MapBox/MapBox';

class Overview extends Component {
    componentDidMount = () => this.onInit(this.props);
    onInit = props => {
        window.filterTag= false;
        props.fetchConnectionsDetail(props.match.params.id);
    }

    render() {
        const { match, connectionsDetail, locations } = this.props;

        let _fake = [];

        return (
            <React.Fragment>
                 <Helmet>
                    <meta charSet="utf-8" />
                    <title>Landsec Connect | Locations |  Overview</title>
                </Helmet>

                <div className="grid-head black">
                    <ControlsBoxBlack >
                        <div className="Name">{connectionsDetail.Name} </div>
                        {this.props.location.state &&
                            this.props.location.state.isComingFromConnection &&
                            // onClick={() => { this.props.history.goBack()  }}
                            <Link to={this.props.location.state.url} ><div className="dark-btn l rto-btn"> Back </div></Link>
                        }
                    </ControlsBoxBlack>
                </div>

                <section id="centre-specific-page">
                    <div className="grid-item-b grid-item-jolly"></div>

                    <div className=" grid-item-b grid-item-3-2 overview-block-01" style={ connectionsDetail.FeatureImage && { backgroundImage: `url(${ connectionsDetail.FeatureImage.Url })` }} />

                    <div className="grid-item-b grid-item-2-2 ModelImage leasing-map-wrapper overview-block-02 connectDetail" style={{overflow: "hidden"}}  >

                        <MapBox isFull={true} locations={connectionsDetail.LocationList} filterTag={[]} isLeasing={false} setFilterHandler={(props)=>this.setFilterHandler(props)} injectedColour={connectionsDetail.Colour}  />

                        <Link to={connectionsDetail.Link ? connectionsDetail.Link : "#"}><div className="dark-btn r leasing-map-btn" style={{whiteSpace : "pre-line"}}> {connectionsDetail.ButtonText} </div></Link>
                    </div>

                    <div className="grid-item-b grid-item-2-2 centre-specific-page-details-container overview-block-03 "  style={{ background : connectionsDetail.Colour }}>  
                      
                            <Carousel className="SliderContainer" height="100%" showArrows={true} showThumbs={false} showStatus={false} showIndicators={false} swipeable={true} emulateTouch={true} infiniteLoop={true} autoPlay={true} >
                                <div className="Slider-Flex">
                                    <div className="centre-specific-page-detailsWrapper"> 
                                        {connectionsDetail.Description} 
                                    </div>
                                </div>

                                {
                                    connectionsDetail.KeyFacts &&  connectionsDetail.KeyFacts !== ""   ?  
                                        
                                        <div>
                                            <div className="centre-specific-page-detailsWrapper connectionDetail" style={{whiteSpace : "pre-line"}}> 
                                                    { connectionsDetail.KeyFacts }
                                            </div>
                                        </div>
                                    
                                    :  _fake.map(() => {})
                                }
                               
                                { connectionsDetail.CaseStudies ?
                                    connectionsDetail.CaseStudies.map((item, index) => {
                                        return(
                                            <div className="case-studies-wrapper" key={index}>
                                                    <div className="case-studies-wrapper-bg" style={{backgroundImage: `url(${item.Image.Url})`}}></div>
                                                    <div className="case-studies-wrapper-content">
                                                        <div className="case-studies-wrapper-content-name">{item.Name}</div>
                                                        <div className="case-studies-wrapper-content-logo" style={{backgroundImage : `url(${item.Logo.Url})`}}></div>
                                                        <div className="case-studies-wrapper-content-summary">{item.Summary}</div>
                                                    </div>
                                            </div>
                                        )
                                    })
                                    :
                                     null
                                }

                            </Carousel>
                    </div>

                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps, { fetchConnectionsDetail })(Overview);